import React from "react";
import Form from "../components/Form";
import HeroPage from "../components/HeroPage";
import contactHeroPageImage from "../assets/images/main-page-hero.jpeg";
import phoneIcon from "../assets/images/phone-icon.svg";
import locationIcon from "../assets/images/location-icon.svg";
import emailIcon from "../assets/images/mail-icon.svg";
import "../assets/styles/pages/contact.css";

function Contact() {
  return (
    <>
      <HeroPage src={contactHeroPageImage} title={"İletişim"} />
      <div
        style={{
          backgroundColor: "white",
          padding: "17px",
          borderRadius: "8px",
          maxWidth: "600px",
          margin: "5px auto",
          textAlign: "center",
          fontSize: "14px",
          lineHeight: "1.5",
          border: "1px solid #ff0000",
        }}
      >
        <h1 style={{ fontSize: "20px", marginBottom: "10px", color: "red" }}>
          UYARI
        </h1>
        <p>
          Dolandırıcılar tarafından Referans Hukuk Bürosu adıyla, aranarak para
          tahsilatı yapılmak istendiği öğrenilmiştir. Büromuz tarafından
          aranılarak para tahsilatı işlemi yapılmadığını ve arayan bu şahıslara
          itibar edilmemesi gerektiğini bilgisini kamuoyunun dikkatlerine önemle
          sunarız.
          <br />
          <br />
          Mesajlarda belirtilen 03129055934 ve 08504950293 vb. numaralar hukuk
          büromuza ait olmadığı gibi mesaj içeriklerinin de hukuk büromuzla
          ilgisinin bulunmadığını, bu şekilde atılan mesajlardan doğacak
          zararlar sebebiyle herhangi bir sorumluluğumuzun bulunmadığını
          bildiririz. Lütfen tarafınıza resmi yollarla tebligat yapılmadıkça
          tarafınıza gönderilen kısa mesajları dikkate almayınız.
        </p>
      </div>
      <div className="contact-container">
        <div className="contact-box">
          <div className="head-office">
            <div className="head-office-title">İletişim Bilgilerimiz</div>
            <div className="head-office-location">
              <div className="head-office-location-icon">
                <img src={phoneIcon} alt="phone icon" />
              </div>
              <span className="head-office-location-text">
                0 (232) 435 15 43
              </span>
            </div>
            <div className="head-office-location">
              <div className="head-office-location-icon">
                <img src={locationIcon} alt="location icon" />
              </div>
              <span className="head-office-location-text">
                Mansuroğlu Mahallesi, 295/2 Sokak No:1/1 Ege Sun Plaza A Blok
                K:3 D:326 Bayraklı / İzmir
              </span>
            </div>
            <div className="head-office-location">
              <div className="head-office-location-icon">
                <img src={emailIcon} alt="email icon" />
              </div>
              <span className="head-office-location-text">
                info@referanshukukburosu.com
              </span>
            </div>
          </div>
          <Form />
        </div>
        <div className="map-box">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3124.6515593316194!2d27.179877!3d38.4495166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b962af70aaa9cf%3A0x42b468af3a1fb0a6!2sEGE%20Sun%20Plaza!5e0!3m2!1str!2str!4v1647182039090!5m2!1str!2str"
            width="100%"
            height="100%"
            loading="lazy"
            title="mapFrame"
            className="map"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Contact;
