import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Layout = () => {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "15px",
              borderRadius: "8px",
              maxWidth: "600px",
              textAlign: "center",
              fontSize: "20px", // Yazı boyutunu küçülttük
              lineHeight: "1.5", // Satır aralığını düzenledik
            }}
          >
            <h1
              style={{ fontSize: "25px", marginBottom: "10px", color: "red" }}
            >
              UYARI
            </h1>
            <p>
              Dolandırıcılar tarafından Referans Hukuk Bürosu adıyla, aranarak
              para tahsilatı yapılmak istendiği öğrenilmiştir. Büromuz
              tarafından aranılarak para tahsilatı işlemi yapılmadığını ve
              arayan bu şahıslara itibar edilmemesi gerektiğini bilgisini
              kamuoyunun dikkatlerine önemle sunarız.
              <br />
              <br />
              Mesajlarda belirtilen 03129055934 ve 08504950293 vb. numaralar
              hukuk büromuza ait olmadığı gibi mesaj içeriklerinin de hukuk
              büromuzla ilgisinin bulunmadığını, bu şekilde atılan mesajlardan
              doğacak zararlar sebebiyle herhangi bir sorumluluğumuzun
              bulunmadığını bildiririz. Lütfen tarafınıza resmi yollarla
              tebligat yapılmadıkça tarafınıza gönderilen kısa mesajları dikkate
              almayınız.
            </p>
            <button
              onClick={handleClosePopup}
              style={{
                marginTop: "15px",
                padding: "8px 16px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "14px",
              }}
            >
              Kapat
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
